import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar, Pie } from 'react-chartjs-2';
import { 
  Chart as ChartJS, 
  CategoryScale, 
  LinearScale, 
  BarElement, 
  Title, 
  Tooltip, 
  Legend,
  ArcElement
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

type User = {
  id: string;
  name: string;
  email: string;
  total_deposit_usdt: number;
  total_withdrawal_usdt: number;
};

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [snackbar, setSnackbar] = useState<string | null>(null);

  const [depositWithdrawalData, setDepositWithdrawalData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Total Deposits',
        data: [],
        backgroundColor: 'rgba(79, 70, 229, 0.6)',
        borderColor: 'rgba(79, 70, 229, 1)',
        borderWidth: 1,
      },
      {
        label: 'Total Withdrawals',
        data: [],
        backgroundColor: 'rgba(156, 163, 175, 0.6)',
        borderColor: 'rgba(156, 163, 175, 1)',
        borderWidth: 1,
      },
    ],
  });

  const [userActivityData, setUserActivityData] = useState({
    labels: ['Active Users', 'Inactive Users'],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: [
          'rgba(79, 70, 229, 0.8)',
          'rgba(156, 163, 175, 0.8)',
        ],
        borderColor: [
          'rgba(79, 70, 229, 1)',
          'rgba(156, 163, 175, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    fetchUsersData();
  }, []);

  const fetchUsersData = async () => {
    try {
      const response = await axios.get('https://api.stakebet.games/getUserData');
      const data = response.data;

      if (Array.isArray(data)) {
        const reversedData = data.reverse();
        setUsers(reversedData);

        // Prepare data for the deposit/withdrawal chart
        const labels = reversedData.slice(0, 7).map(user => user.name);
        const deposits = reversedData.slice(0, 7).map(user => user.total_deposit_usdt);
        const withdrawals = reversedData.slice(0, 7).map(user => user.total_withdrawal_usdt);

        setDepositWithdrawalData({
          labels,
          datasets: [
            {
              label: 'Total Deposits',
              data: deposits,
              backgroundColor: 'rgba(79, 70, 229, 0.6)',
              borderColor: 'rgba(79, 70, 229, 1)',
              borderWidth: 1,
            },
            {
              label: 'Total Withdrawals',
              data: withdrawals,
              backgroundColor: 'rgba(156, 163, 175, 0.6)',
              borderColor: 'rgba(156, 163, 175, 1)',
              borderWidth: 1,
            },
          ],
        });

        // Prepare data for the user activity pie chart
        const activeUsers = reversedData.filter(user => user.total_deposit_usdt > 0).length;
        const inactiveUsers = reversedData.length - activeUsers;

        setUserActivityData({
          labels: ['Active Users', 'Inactive Users'],
          datasets: [
            {
              data: [activeUsers, inactiveUsers],
              backgroundColor: [
                'rgba(79, 70, 229, 0.8)',
                'rgba(156, 163, 175, 0.8)',
              ],
              borderColor: [
                'rgba(79, 70, 229, 1)',
                'rgba(156, 163, 175, 1)',
              ],
              borderWidth: 1,
            },
          ],
        });
      } else {
        console.error('Unexpected response structure:', data);
        setUsers([]);
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setUsers([]);
    }
  };

  const handleDeleteUser = async (id: string, name: string) => {
    try {
      await axios.delete('https://api.stakebet.games/deleteUser', {
        data: { id },
      });
      fetchUsersData();
      setSnackbar(`User ${name} deleted successfully`);
      setTimeout(() => setSnackbar(null), 3000); // Hide snackbar after 3 seconds
    } catch (error) {
      console.error('Failed to delete user:', error);
    }
  };

  const filteredUsers = searchTerm
    ? users.filter(user =>
        user.id.toString().includes(searchTerm) ||
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : users;

  return (
    <div className='ml-0 lg:ml-[256px] p-8 '>
      <h1 className="text-3xl font-bold mb-8 text-white">Users</h1>

      {snackbar && (
        <div className="mb-4 text-center text-white bg-green-500 py-2 px-4 rounded-lg shadow-md">
          {snackbar}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div className="bg-[#37384A] p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4 text-white">Deposits vs Withdrawals</h2>
          <Bar 
            data={depositWithdrawalData} 
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
              },
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                  ticks: {
                    color: 'white',  // This line changes the x-axis label color to white
                  },
                  border: {
                    color: 'white'  // This changes the x-axis line color to white
                  }
                },
                y: {
                  beginAtZero: true,
                  grid: {
                    display: false,
                  },
                  ticks: {
                    color: 'white',  // This line changes the text color to white
                    callback: function(value) {
                      return (value as number).toLocaleString();
                    },
                    stepSize: 200000,
                    max: 1600000,
                  },
                  border: {
                    color: 'white'  // This changes the x-axis line color to white
                  }
                },
              },
            }}
          />
        </div>

        <div className="bg-[#37384A] p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4 text-white">User Activity</h2>
          <div className="flex items-center justify-center">
            <div style={{ width: '250px', height: '250px' }}>
              <Pie 
                data={userActivityData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'bottom',
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <h1 className="text-2xl  text-white font-semibold mb-4">{`Total Users: ${users.length}`}</h1>

      <div className="mb-4 flex justify-end mb-5">
        <label htmlFor="search" className="sr-only">Search</label>
        <div className="relative w-1/2">
          <input
            type="text"
            id="search"
            className="bg-[#37384A] w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 shadow-sm focus:outline-none focus:ring-2  focus:border-transparent"
            placeholder="Search by email, name or UID"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg className="h-5 w-5 text-gray-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg bg-white">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-white uppercase bg-[#646ECD]">
            <tr>
              <th scope="col" className="py-3 px-6">S.No</th>
              <th scope="col" className="py-3 px-6">Name</th>
              <th scope="col" className="py-3 px-6">Email</th>
              <th scope="col" className="py-3 px-6">UID</th>
              <th scope="col" className="py-3 px-6">Total Deposit (USDT)</th>
              <th scope="col" className="py-3 px-6">Total Withdrawal (USDT)</th>
              <th scope="col" className="py-3 px-6">PNL (USDT)</th>
              <th scope="col" className="py-3 px-6">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr key={user.id} className="group bg-[#37384A] border-b border-gray-200 hover:bg-gray-50">
                <td className="text-white group-hover:text-gray-600 py-4 px-6">{index + 1}</td>
                <td className="text-white group-hover:text-gray-600 py-4 px-6">{user.name}</td>
                <td className="text-white group-hover:text-gray-600 py-4 px-6">{user.email}</td>
                <td className="text-white group-hover:text-gray-600 py-4 px-6">{user.id}</td>
                <td className=" group-hover:text-gray-600 py-4 px-6 text-green-600">{`$${user.total_deposit_usdt.toFixed(2)}`}</td>
                <td className=" group-hover:text-gray-600 py-4 px-6 text-red-600">{`$${user.total_withdrawal_usdt.toFixed(2)}`}</td>
                <td className={`text-white group-hover:text-gray-600 py-4 px-6 ${user.total_deposit_usdt - user.total_withdrawal_usdt >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                  {`$${(user.total_deposit_usdt - user.total_withdrawal_usdt).toFixed(2)}`}
                </td>
                <td className="py-4 px-6">
                  <button
                    onClick={() => handleDeleteUser(user.id, user.name)}
                    className="text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;