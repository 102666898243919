import React, { useEffect, useState } from 'react';

interface House {
  uid: string;
  name: string;
  max: number;
  current: number;
}

const House: React.FC = () => {
  const [transferDetails, setTransferDetails] = useState<House[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [editData, setEditData] = useState<{ max: string; current: string; uid: string }[]>([]);
  const [updatingUid, setUpdatingUid] = useState<string | null>(null);
  const [mode, setMode] = useState(localStorage.getItem('mode') || 'manual');
  const [loading, setLoading] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    fetchTransferDetails();

    if (mode === 'automatic') {
      const interval = setInterval(fetchTransferDetails, 3000); // Refresh every 3 seconds in automatic mode
      setIntervalId(interval);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [mode]);

  const fetchTransferDetails = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://api.stakebet.games/gethouse');
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data: House[] = await response.json();
      setTransferDetails(data);
      const initialEditData = data.map(({ uid, max, current }) => ({ uid, max: max.toString(), current: current.toString() }));
      setEditData(initialEditData);
    } catch (error) {
      console.error(`Error fetching transfer details:`, error);
      setTransferDetails([]);
    } finally {
      setLoading(false);
    }
  };

  const handleEditChange = (uid: string, field: 'max' | 'current', value: string) => {
    setEditData(editData.map(item => item.uid === uid ? { ...item, [field]: value } : item));
  };

  const handleEdit = async (uid: string) => {
    setUpdatingUid(uid);
    const item = editData.find(item => item.uid === uid);
    if (!item) {
      console.error('Edit data not found');
      setUpdatingUid(null);
      return;
    }

    try {
      const response = await fetch('https://api.stakebet.games/updatehouse', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid, max: item.max, current: item.current }),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      setTransferDetails(transferDetails.map(house => house.uid === uid ? { ...house, max: parseInt(item.max), current: parseInt(item.current) } : house));
    } catch (error) {
      console.error('Error updating house details:', error);
    } finally {
      setUpdatingUid(null);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredDepositData = transferDetails.filter(item => {
    const uidStr = item.uid.toString();
    const searchTermLower = searchTerm.toLowerCase();
    return uidStr.includes(searchTerm) || item.name.toLowerCase().includes(searchTermLower);
  });

  const handleModeChange = async (mode: 'manual' | 'automatic') => {
    setMode(mode);
    localStorage.setItem('mode', mode);
    if (intervalId) clearInterval(intervalId);

    const url = mode === 'manual' ? 'https://api.stakebet.games/stop-house' : 'https://api.stakebet.games/start-house';
    try {
      const response = await fetch(url, { method: 'GET' });
      if (!response.ok) throw new Error('Network response was not ok');
      console.log(`${mode} mode activated:`, await response.json());
    } catch (error) {
      console.error(`Failed to activate ${mode} mode:`, error);
    }

    if (mode === 'automatic') {
      const interval = setInterval(fetchTransferDetails, 3000);
      setIntervalId(interval);
    }
  };

  const handleResetHouse = async () => {
    try {
      const response = await fetch('https://api.stakebet.games/reset-house', { method: 'GET' });
      if (!response.ok) throw new Error('Network response was not ok');
      console.log('House reset successfully:', await response.json());
      window.location.reload();
    } catch (error) {
      console.error('Failed to reset house:', error);
    }
  };

  const handleWinHouse = async () => {
    try {
      const response = await fetch('https://api.stakebet.games/win-house', { method: 'GET' });
      if (!response.ok) throw new Error('Network response was not ok');
      console.log('House reset successfully:', await response.json());
      window.location.reload();
    } catch (error) {
      console.error('Failed to reset house:', error);
    }
  };

  return (
    <div className='ml-0 lg:ml-[256px]'>
      <div className="flex items-center mb-4 justify-between">
        <div className="flex">
          <button
            className={`text-2xl font-bold py-2 px-4 rounded mr-2 ${mode === 'manual' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
            onClick={() => handleModeChange('manual')}
          >
            Manual
          </button>
          <button
            className={`text-2xl font-bold py-2 px-4 rounded ${mode === 'automatic' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
            onClick={() => handleModeChange('automatic')}
          >
            Automatic
          </button>
        </div>

        <div className="flex">
          <button
            className="text-2xl font-bold py-2 px-4 rounded bg-red-500 text-white mr-2"
            onClick={handleResetHouse}
          >
            Reset House
          </button>
          <button
            className="text-2xl font-bold py-2 px-4 rounded bg-green-500 text-white"
            onClick={handleWinHouse}
          >
            Win House
          </button>
        </div>
      </div>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by name or UID"
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border rounded w-full"
          disabled={mode === 'automatic'}
        />
      </div>

      <div className={`h-[690px] overflow-scroll ${mode === 'manual' ? '' : 'opacity-50'}`}>
        <table className="w-full">
          <thead>
            <tr>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">UID</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Name</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">MAX</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Current</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredDepositData.map((request, index) => (
              <tr key={request.uid}>
                <td className="py-3 px-8 border-b border-gray-200 text-white">{index + 1}</td>
                <td className="py-3 px-8 border-b border-gray-200 text-white">{request.uid}</td>
                <td className="py-3 px-8 border-b border-gray-200 text-white">{request.name}</td>
                <td className="py-3 px-8 border-b border-gray-200 text-white">
                  <input
                    type="number"
                    defaultValue={request.max}
                    disabled={loading || mode === 'automatic' || updatingUid === request.uid}
                    onChange={(e) => handleEditChange(request.uid, 'max', e.target.value)}
                    className="p-1 border rounded w-full"
                  />
                </td>
                <td className="py-3 px-8 border-b border-gray-200">
                  <input
                    type="number"
                    defaultValue={request.current}
                    disabled={loading || mode === 'automatic' || updatingUid === request.uid}
                    onChange={(e) => handleEditChange(request.uid, 'current', e.target.value)}
                    className="p-1 border rounded w-full"
                  />
                </td>
                <td className="py-3 px-8 border-b border-gray-200">
                  <button
                    onClick={() => handleEdit(request.uid)}
                    disabled={loading || mode === 'automatic' || updatingUid === request.uid}
                    className={`py-2 px-4 rounded ${updatingUid === request.uid ? 'bg-gray-500 text-white' : 'bg-green-500 text-white'}`}
                  >
                    {updatingUid === request.uid ? 'Updating...' : 'Update'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && <p className="text-center mt-4">Updating details, please wait...</p>}
      </div>
    </div>
  );
};

export default House;
